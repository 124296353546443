body {
  margin: 0;
  font-family: "Nunito", 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  height: 100svh;
  height: -webkit-fill-available;
  overflow-x:hidden;

  .ant-typography {
    font-family: 'Nunito', 'Helvetica Neue', sans-serif !important;
    /* color: #B3B3B3; only dark mode */
    /* color: #323232;  */

    /* @media (prefers-color-scheme: dark) {
      color: #B3B3B3
    } */
  }
}

.link {
  color: #64ebfd; /* Very light blue */
  text-decoration: none;
}


